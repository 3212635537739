import apiConfig from './config';
import helper from '../../utils/helper';

const getArchivoUrl = (id) => {
  const token = helper.getToken();
  
  return `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/archivos/${ id }/descargar?authorization=${ token }`;
};

const exportedObject = {
	getArchivoUrl
}

export default exportedObject;