import React, { Component } from 'react';
import { Modal } from 'antd';

import UserFormulario from '../../components/Users/UserFormulario'

class UserFormularioModal extends Component {

  handleClose = () => {
    this.props.onCancel();
  };

  handleFormSubmit = (data) => {
    this.props.onFormSubmit(data);
  };

  onFormUploadValidators = (error) => {
    if (this.formRef) {
      this.formRef.actualizarListaErrores(error);
    }
  };
  
  render () {
    console.log(this.props);
    return (
      <div>
        <Modal
          title={ this.props.user && this.props.user.id ? "Editar Usuario": "Nueva Usuario" }
          visible= { this.props.visible }
          onCancel={ this.handleClose }
          width={ 550 }
          footer={ null }
          maskClosable={ false }
        >
          <UserFormulario
            ref={ this.setFormRef }
            onFormSubmit={ this.handleFormSubmit }
            onFormCancel={ this.handleClose }
            user={ this.props.user }
            guardando={ this.props.guardando }
            errores={ this.props.errores }
          />
        </Modal>
      </div>
    );
  }
}

export default UserFormularioModal;
