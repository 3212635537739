import apiConfig from './config';
//import data from './data-json/permisos';

const getUsers = (queryString, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/users${ queryString }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
	.then(apiConfig.methods.checkStatus)
	.then(apiConfig.methods.parseJSON)
	.then(success)
	.catch(apiConfig.methods.error)
	.then(error);
};

const getUser = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/users/${ id }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const createUser = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/users`, {
		method: 'post',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const updateUser = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/users/${ data.id }`, {
		method: 'put',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const deleteUser = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/users/${ id }`, {
		method: 'delete',
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const exportedObject = {
	getUsers,
	getUser,
	createUser,
	updateUser,
	deleteUser
}

export default exportedObject;