import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Config from '../services/api/config';
import store from '../../src/store';

const Authorization = (entidad) =>
  (WrappedComponent, accion) => {
    return class WithAuthorization extends Component {

      state = {
        user: {}
      };

      componentDidMount() {
        const storeState = store.getState();
        if (storeState && storeState.data && storeState.data.user) {
          this.setState({
            user: storeState.data.user
          });
        }
      }

      getToken = () => {
        return localStorage.getItem(Config.constants.ACCESS_TOKEN_KEY);
      };

      getRefreshToken = () => {
        return localStorage.getItem(Config.constants.REFRESH_TOKEN_KEY);
      };

      getTokenExpiresIn = () => {
        return localStorage.getItem(Config.constants.ACCESS_TOKEN_EXPIRES_IN);
      };

      getTokenExpiresAt = () => {
        return localStorage.getItem(Config.constants.ACCESS_TOKEN_EXPIRES_AT);
      };

      getTokenExpirationDate = () => {
        const expires_at = this.getTokenExpiresAt();
        if (!expires_at) { return null; }

        const date = new Date(0);
        date.setSeconds(expires_at);

        return date;
      };

      isTokenExpired = () => {
        const expirationDate = this.getTokenExpirationDate();
        return expirationDate < new Date();
      };

      checkAuth = () => {
        const token = this.getToken();
        const refreshToken = this.getRefreshToken();
        const isTokenExpired = this.isTokenExpired();

        return token && refreshToken && !isTokenExpired;
      };

      notAuthorized = () => {
        return <h1>No esta autorizado</h1>;
      };

      render () {
        if (this.checkAuth()) {
          if (entidad === 'full') {
            return <WrappedComponent {...this.props} />;
          } else {
            const { permisos } = this.state.user;
            if (permisos) {
              const entidades = permisos.filter((permiso) => {
                return permiso && permiso.entidad === entidad;
              });

              if (entidades && entidades.length > 0) {
                let _entidad = entidades[0];
                if (_entidad.full_acceso || _entidad[accion]) {
                  return <WrappedComponent {...this.props} />;
                }
              }
            }
            return this.notAuthorized();
          }
        } else {
          return <Redirect to={
            {
              pathname: '/auth/login'
            }
          }/>;
        }
      }
    }
  };

export default Authorization;
