import apiConfig from './config';
import helper from '../../utils/helper';

const getMesaPartes = (queryString, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas${ queryString }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
	.then(apiConfig.methods.checkStatus)
	.then(apiConfig.methods.parseJSON)
	.then(success)
	.catch(apiConfig.methods.error)
	.then(error);
};

const getMesaParte = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas/${ id }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const createMesaParte = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas`, {
		method: 'post',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const updateMesaParte = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas/${ data.id }`, {
		method: 'put',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const deleteMesaParte = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
	
	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas/${ id }`, {
		method: 'delete',
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const sendEmail = (id, data, success, error) => {
	const token = helper.getToken();

	let formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (key !== 'files') {
			if (typeof data[key] === 'object') {
				data[key].forEach((item, index) => {
					if (typeof item === 'object') {
						Object.keys(item).forEach((k) => {
							formData.append(`${key}[${index}][${k}]`, item[k]);
						});
					} else {
						formData.append(`${key}[${index}]`, item);
					}
				});
			} else {
				formData.append(key, data[key]);
			}

		} else if (data[key] && data[key].length > 0){
			data[key].forEach((item, index) => {
				formData.append(`files[${index}]`, item);
			});
		}
	});

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mesas/${ id }/email`, {
		method: 'post',
		body: formData,
		headers: {
			Authorization: `Bearer ${ token }`
		}
	})
	.then(apiConfig.methods.checkStatus)
	.then(apiConfig.methods.parseJSON)
	.then(success)
	.catch(apiConfig.methods.error)
	.then(error);
};

const createMpv = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	let formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (key === 'files' && data[key] && data[key].length > 0) {
			data[key].forEach((item, index) => {
				formData.append(`files[${index}]`, item);
			});
		} else {
			formData.append(key, data[key]);
		}
	});

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/mpv`, {
		method: 'post',
		body: formData,
		headers: {
			Authorization: `Bearer ${ token }`
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const exportedObject = {
	getMesaPartes,
	getMesaParte,
	createMesaParte,
	updateMesaParte,
	deleteMesaParte,
	sendEmail,
	createMpv
}

export default exportedObject;