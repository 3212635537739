const constants = {
  URL_API: '',
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  ACCESS_TOKEN_EXPIRES_IN: 'expires_in',
  ACCESS_TOKEN_EXPIRES_AT: 'expires_at',
  USUARIO_ID: 'USUARIO_ID',
  API_VERSION: 'v1'
}

const exportedObject = {
	constants
}

export default exportedObject;