import React, { Component } from 'react';
import { Col, Row, Form, Input, Button, Select, Upload, Divider, message } from 'antd';
import CKEditor from 'react-ckeditor-component';
import Helpers from '../../utils/helper';
const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

class EmailFormulario extends Component {
  state = {
    de: this.props.from || '',
    destinatarios: this.props.destinatarios || [],
    cc: this.props.cc || [],
    bcc: this.props.bcc || [],
    asunto: this.props.subject,
    mensaje: this.props.template,
    authorization: Helpers.getToken(),
    files: [],
    files_temp: [],
    estado: null,
    responsable: null,
    validators: {
			de: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.'
          },
          {
            test: /^[^@]+@[^\.]+\..+/,
            message: 'El campo acepta solo correo electrónico.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      destinatarios: {
        rules: [
          {
            test: (value) => value.toString().length > 0,
            message: 'Campo obligatorio.'
          },
          {
            test: (value) => {
              let valido = true;
              value.map(item => {
                if (/^[^@]+@[^\.]+\..+/.test(item)) {
                  valido = true;
                } else {
                  valido = false;
                }
              });
              return valido;
            },
            message: 'Ingrese un correo electronico valido.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      asunto: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      mensaje: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      cc: {
        rules: [
          {
            test: (value) => {
              let valido = true;
              value.map(item => {
                if (/^[^@]+@[^\.]+\..+/.test(item)) {
                  valido = true;
                } else {
                  valido = false;
                }
              });
              return valido;
            },
            message: 'Ingrese un correo electronico valido.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      bcc: {
        rules: [
          {
            test: (value) => {
              let valido = true;
              value.map(item => {
                if (/^[^@]+@[^\.]+\..+/.test(item)) {
                  valido = true;
                } else {
                  valido = false;
                }
              });
              return valido;
            },
            message: 'Ingrese un correo electronico valido.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      estado: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
      responsable: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.'
          }
        ],
        errors: [],
        valid: true,
        state: ''
      },
    }
  };

  handleChangeDe = (e, fieldName) => {
    this.setState({
      de: e.target.value
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeCc = (value) => {
    this.setState({
      cc: value
    });

    this.uploadValidators('cc', value);
  };


  handleChangeBcc = (value) => {
    this.setState({
      bcc: value
    });
    
    this.uploadValidators('bcc', value);
  };


  handleChangeAsunto = (e, fieldName) => {
    this.setState({
      asunto: e.target.value
    });

    this.uploadValidators(fieldName, e.target.value);
  };
  
  handleChangeEnviarA = (value) => {
    this.setState({
      destinatarios: value
    });
    this.uploadValidators('destinatarios', value);
  };

  handleChangeMensaje= (e) => {
    let newContent = e.editor.getData();
    this.setState({
      mensaje: newContent
    });
  };

  onChangeEstado = (value) => {
    this.setState({ estado: value });
    this.uploadValidators('estado', value);
  }

  onChangeResponsable = (value) => {
    this.setState({ responsable: value });
    this.uploadValidators('responsable', value);
  }
  
  handleSave = () => {
    const valid = this.isFormValid();

    if (valid) {
      this.props.onFormSubmit({
        de: this.state.de,
        destinatarios: this.state.destinatarios,
        cc: this.state.cc,
        bcc: this.state.bcc,
        asunto: this.state.asunto,
        mensaje: this.state.mensaje,
        files: this.state.files,
        authorization: this.state.authorization,
        responsable: this.state.responsable,
        estado: this.state.estado,
      });
    }
  };

  handleCancel = () => {
    this.props.onFormClose();
  };

  handleBeforeUploadAttachedFiles = (file) => {
    const isPDFandDOC = file.type === 'application/pdf' ? 'application/pdf': file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '';

    if (!isPDFandDOC) {
      message.error('¡Solo puedes subir archivos PDF y DOC!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('¡El documento debe de ser menor a 2MB!');
    }
    const isLt5files = this.state.files.length < 5;
    if (!isLt5files) {
      message.error('¡Máximo 5 archivos!');
    }
    var esValido = isPDFandDOC && isLt2M && isLt5files;
    if (esValido) {
      let files = this.state.files.slice();
      files.push(file);
      this.setState({
        files
      });
    }

    return false;
  };

  handleClickDeleteFile = (index) => {
    let files = this.state.files.slice();
    files.splice(index, 1);
    this.setState({
      files
    });
  };

  handleClickDeleteFileTemp = (index) => {
    let files_temp = this.state.files_temp.slice();
    files_temp.splice(index, 1);
    this.setState({
      files_temp
    });
  };

  uploadValidators(fieldName, value) {
    let validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    let respuesta =  Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators
      });
    }
    return respuesta.status;
  };

  render () {
    const es_recibido = this.props.historiales &&
    this.props.historiales.length > 0 &&
    this.props.historiales.filter((h) => h.tipo_operacion === 'RECIBIDO').length > 0;

    const es_en_atencion = this.props.historiales &&
    this.props.historiales.length > 0 &&
    this.props.historiales.filter((h) => h.tipo_operacion === 'EN ATENCIÓN').length > 0;

    const es_archivado = this.props.historiales &&
    this.props.historiales.length > 0 &&
    this.props.historiales.filter((h) => h.tipo_operacion === 'ARCHIVADO').length > 0;

    return (
      <Row>
        <Col span={ 24 }>
          <FormItem
            {...formItemLayout}
            validateStatus={ this.state.validators.responsable.valid ? 'success': 'error' }
            style={{ marginBottom:'5px'}}
            label="Responsable"
          >
            <Select
              showSearch
              placeholder="Seleccione"
              onChange={ this.onChangeResponsable }
              value={ this.state.responsable }
              style={{ width: '100%', }}
            >
              {
                this.props.usuarios && this.props.usuarios.length > 0 &&
                this.props.usuarios.map((item, index) => {
                  return (
                    <Option key={ index } value={ item.name }>{ item.name }</Option>
                  );
                })
              }
            </Select>
            { this.mostrarErroresValidacion('responsable') }
          </FormItem>
          <FormItem
            {...formItemLayout}
            validateStatus={ this.state.validators.estado.valid ? 'success': 'error' }
            style={{ marginBottom:'5px'}}
            label="Estados"
          >
            <Select
              showSearch
              placeholder="Seleccione"
              onChange={ this.onChangeEstado }
              value={ this.state.estado }
              style={{ width: '100%', }}
            >
              <Option value="RECIBIDO" disabled={ es_recibido }>RECIBIDO</Option>
              <Option value="EN ATENCIÓN" disabled={ es_en_atencion }>EN ATENCIÓN</Option>
              <Option value="ARCHIVADO" disabled={ es_archivado }>ARCHIVADO</Option>
            </Select>
            { this.mostrarErroresValidacion('estado') }
          </FormItem>
          <FormItem
            {...formItemLayout}
            style={{ marginBottom:'5px'}}
            label="De"
          >
            <Input
              onChange={ (e) => this.handleChangeDe(e, 'de') }
              value={ this.state.de }
              autoComplete='off'
              disabled={ true }
              id="de" />
          </FormItem>
          <FormItem
            {...formItemLayout}
            style={{ marginBottom:'5px'}}
            validateStatus={ this.state.validators.destinatarios.valid ? 'success': 'error' }
            label="Enviar a"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              help={ this.state.errors }
              placeholder="Escriba el Correo electrónico"
              onChange={ this.handleChangeEnviarA }
              value={this.state.destinatarios}
              notFoundContent='No se ha encontrado registros'
            >
            </Select>
            { this.mostrarErroresValidacion('destinatarios') }
          </FormItem>
          <FormItem
            {...formItemLayout}
            validateStatus={ this.state.validators.cc.valid ? 'success': 'error' }
            style={{ marginBottom:'5px'}}
            label="Cc"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              help={ this.state.errors }
              placeholder="Escriba el Correo electrónico"
              onChange={ this.handleChangeCc }
              value={this.state.cc}
              notFoundContent='No se ha encontrado registros'
            >
            </Select>
            { this.mostrarErroresValidacion('cc') }
          </FormItem>
          <FormItem
            style={{ marginBottom:'5px'}}
            {...formItemLayout}
            validateStatus={ this.state.validators.bcc.valid ? 'success': 'error' }
            label="Bcc"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              help={ this.state.errors }
              placeholder="Escriba el Correo electrónico"
              onChange={ this.handleChangeBcc }
              value={this.state.bcc}
              notFoundContent='No se ha encontrado registros'
            >
            </Select>
            { this.mostrarErroresValidacion('bcc') }
          </FormItem>
          <FormItem
            {...formItemLayout}
            style={{ marginBottom:'5px'}}
            label="Asunto"
            validateStatus={ this.state.validators.asunto.valid ? 'success': 'error' }
            help={ this.state.errors }
          >
            <Input
              onChange={ (e) => this.handleChangeAsunto(e, 'asunto') }
              value={ this.state.asunto }
              autoComplete='off'
              placeholder="Escriba el Asunto"
              id="asunto" />
              { this.mostrarErroresValidacion('asunto') }

          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Mensaje"
          >
            <CKEditor
              content={ this.state.mensaje }
              events={{
                'change': this.handleChangeMensaje
              }}
              ref='componentCKEditor'
            />
          </FormItem>
        </Col>
        <Col span={ 24 }>
          <FormItem
            {...formItemLayoutWithOutLabel }
            label=""
          >
            <div className='attachments_action'>
              <Upload
                showUploadList={false}
                name="files"
                className="producto-uploader"
                action="//app.ccpll.test/api/archivos"
                beforeUpload={ this.handleBeforeUploadAttachedFiles }
              >
                <div className="link">Archivos adjuntos</div>
                <small>Máx 5 archivos de 2MB</small>
              </Upload>
            </div>
            <div className='attachments__files'>
              {
                this.state.files && this.state.files.length > 0 ?
                this.state.files.map((item, index) => {
                  return <div className='attachments__file'>
                    <div className='file__action' onClick={ () => { this.handleClickDeleteFile(index) } }>×</div>
                    <div className='file__name'>{ item.name }</div>
                  </div>;
                }):
                ''
              }
              {
                this.state.files_temp && this.state.files_temp.length > 0 ?
                this.state.files_temp.map((item, index) => {
                  return (
                    <div key={index} className='attachments__file'>
                      <div className='file__action' onClick={ () => { this.handleClickDeleteFileTemp(index) } }>×</div>
                      <div className='file__name'>{ item.name }</div>
                    </div>);
                }):
                ''
              }
            </div>
            <Divider style={{ background: '#02020217'}} />
          </FormItem>
        </Col>
        {
          Helpers.tienePermiso(this.props.permisos, 'Actualizar mesa partes') &&
          <Col span={ 24 }>
            <FormItem
              {...formItemLayoutWithOutLabel }
              label=""
            >
              <Button
                type="primary"
                onClick={ this.handleSave }
                htmlType="submit"
                loading={ this.props.guardando }
                >Enviar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={ this.handleCancel }>
                Cancelar
              </Button>
            </FormItem>
          </Col>
        }
      </Row>
    );
  }
}

export default EmailFormulario;
