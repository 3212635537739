import React, { Component } from 'react';
import MpvFormulario from '../../../components/Mpv/MpvFormulario';

class IndexMpv extends Component {
  render() {
    return (
      <>
        <MpvFormulario/>
      </>
    );
  }
}

export default IndexMpv;