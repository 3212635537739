import React, {useRef} from 'react'
import { message } from 'antd';
import LoginService from '../../services/api/login';
import { Link } from 'react-router-dom'

import './dropdown.css'

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener('mousedown', (e) => {
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle('active')
    } else {
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove('active')
      }
    }
  })
}

const handleClickSalir = (props) => {
  LoginService.logout();
  message.success('Session cerrado correctamente.');
  props.history.push('/auth/login');
};
  
const DropdownUser = props => {
  const dropdown_toggle_el = useRef(null)
  const dropdown_content_el = useRef(null)

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el)
  
  return (
    <div className='dropdown'>
      <button ref={dropdown_toggle_el} className="dropdown__toggle">
        {
          props.icon ? <i className={props.icon}></i> : ''
        }
        {
          props.badge ? <span className='dropdown__toggle-badge'>{ props.badge }</span> : ''
        }
        {
          props.customToggle ? props.customToggle() : ''
        }
      </button>
      <div ref={dropdown_content_el} className="dropdown__content">
          {
            // props.contentData && props.renderItems ? props.contentData.map((item, index) => props.renderItems(item, index)) : ''
          }
          {
            props.user_menu &&
            props.user_menu.length > 0 &&
            props.user_menu.map((item, index) => {
              if (item.id === 'cerra_sesion') {
                return (
                  <div onClick={ () => handleClickSalir(props) } style={{ cursor: 'pointer' }} key={index}>
                    <div className="notification-item">
                      <i className={item.icon}></i>
                      <span>{item.content}</span>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Link to={ item.link } key={index}>
                    <div className="notification-item">
                      <i className={item.icon}></i>
                      <span>{item.content}</span>
                    </div>
                  </Link>
                );
              }
            })
          }
          {
            props.renderFooter ? (
              <div className="dropdown__footer">
                {props.renderFooter()}
              </div>
            ) : ''
          }
      </div>
    </div>
  )
}

export default DropdownUser
