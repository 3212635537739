import React, { useState, useEffect } from 'react';
import { message, Menu, Dropdown, Popover } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import DashboardService from '../services/api/dashboard';
import Chart from 'react-apexcharts';

const Dashboard = () => {
  const [atendidos, setAtendidos] = useState([]);
  const [colegiados, setColegiados] = useState([]);
  const [filtro_colegiado, setFiltroColegiado] = useState('EsteAnio');
  const [filtro_atendido, setFiltroAtendido] = useState('EsteAnio');

  useEffect(() => {
    cargarAtendidos(filtro_atendido);
    cargarColegiados(filtro_colegiado);
  }, []);

  const cargarAtendidos = (filtro_atendido) => {
    DashboardService.getMesaPartesAtendidos('?filtro_por=RankingVentaFecha.'+filtro_atendido, (response) => {
      if (response) {
        setAtendidos(response);
      }
    }, (err) => {
      if (err) {
        message.error(err.message);
      }
    })
  }

  const cargarColegiados = (filtro_colegiado) => {
    DashboardService.getColegiados('?filtro_por=RankingVentaFecha.'+filtro_colegiado, (response) => {
      if (response) {
        setColegiados(response);
      }
    }, (err) => {
      if (err) {
        message.error(err.message);
      }
    })
  }

  let series = [];
  let labels = [];
  let cantidad_atendidos = 0;
  atendidos && 
  atendidos.length > 0 &&
  atendidos.map(item => {
    series.push(item.cantidad);
    labels.push(item.estado);
    cantidad_atendidos += item.cantidad;
  })
  const options =  {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  let array_colegiados = [];
  let array_no_colegiados = [];
  let array_tipo_documentos = [];
  let array_dias = [];
  colegiados && 
  colegiados.length > 0 &&
  colegiados.map(item => {
    array_colegiados.push(item.colegiados);
    array_no_colegiados.push(item.no_colegiados);
    array_tipo_documentos.push(parseInt(item.tipo_documento_codigo));
    array_dias.push(item.fecha);
  })


  const series1 = [{
    name: 'Colegiados',
    type: 'column',
    data: array_colegiados
  }, {
    name: 'No Colegiados',
    type: 'line',
    data: array_no_colegiados
  }, {
    name: 'Tipo Documento',
    type: 'line',
    data: array_tipo_documentos
  }];



  const options1 = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [0, 4]
    },
    title: {
      text: 'Colegiados'
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: '',
      },
    
    }, {
      opposite: true,
      title: {
        text: ''
      }
    }]
  }

  const onClickFiltroAtencion = (filtro_por) => {
    setFiltroAtendido(filtro_por);
    cargarAtendidos(filtro_por);
  }

  const onClickFiltroColegiado = (filtro_por) => {
    setFiltroColegiado(filtro_por);
    cargarColegiados(filtro_por);
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={ () => onClickFiltroAtencion('Hoy') }>
        Hoy
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroAtencion('EstaSemana') }>
        Esta Semana
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroAtencion('SemanaAnterior') }>
        Semana Anterior
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroAtencion('EsteMes') }>
        Este Mes
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroAtencion('MesAnterior') }>
       Mes Anterior
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroAtencion('EsteAnio') }>
        Este Año
      </Menu.Item>
    </Menu>
  );

  
  const menuColegiado = (
    <Menu>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstHoy') }>
        Hoy
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstEstaSemana') }>
        Esta Semana
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstSemanaAnterior') }>
        Semana Anterior
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstEsteMes') }>
        Este Mes
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstMesAnterior') }>
       Mes Anterior
      </Menu.Item>
      <Menu.Item onClick={ () => onClickFiltroColegiado('EstEsteAnio') }>
        Este Año
      </Menu.Item>
    </Menu>
  );

  const themeReducer = null;

  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div className="row">
        <div className="col-4">
          <div className="card" style={{ marginBottom: 32 }}>
            <div className="card__header">
              <h3>MESA DE PARTES ATENCIONES</h3>
              <div>
                <Dropdown overlay={menu} placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    { filtro_atendido === 'Hoy' ? 'Hoy': filtro_atendido === 'EstaSemana' ? 'Esta Semana': filtro_atendido === 'SemanaAnterior' ? 'Semana Anterior': 
                    filtro_atendido === 'EsteMes' ? 'Este Mes': filtro_atendido === 'MesAnterior' ? 'Mes Anterior': filtro_atendido === 'EsteAnio' ? 'Este Año': '' } <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="card__body">
              <Chart
                options={themeReducer === 'theme-mode-dark' ? {
                  ...options,
                  theme: { mode: 'dark'}
                } : {
                  ...options,
                  theme: { mode: 'light'}
                }}
                series={series}
                type='pie'
              />
              <div className="card__body-alias">
                {
                  atendidos && 
                  atendidos.length > 0 &&
                  atendidos.map((item, index) => {
                    return (
                      <div key={index}>
                        { item.estado }: { item.cantidad }
                      </div>
                    );
                  })
                }
                <div style={{ textAlign: 'right' }}>TOTAL DOCUMENTO: { cantidad_atendidos }</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="card" style={{ marginBottom: 32 }}>
            <div className="card__header">
              <h3>ESTADISTICA DE COLEGIADOS &nbsp;&nbsp;
              <Popover placement="bottomRight" content={
                <div>
                  <div>6 - RUC</div>
                  <div>1 - DNI</div>
                  <div>7 - PASAPORTE</div>
                  <div>4 - CARNET DE EXTRANJERIA</div>
                  <div>0 - PERMISO TEMPORAL DE PERMANENCIA</div>
                </div>
                } title="Leyenda Tipo Doucmento">
                <span style={{ color: 'blue', cursor: 'pointer' }}>Leyenda</span>
              </Popover>
              </h3>
              <div>
                <Dropdown overlay={menuColegiado} placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    { filtro_atendido === 'Hoy' ? 'Hoy': filtro_atendido === 'EstaSemana' ? 'Esta Semana': filtro_atendido === 'SemanaAnterior' ? 'Semana Anterior': 
                    filtro_atendido === 'EsteMes' ? 'Este Mes': filtro_atendido === 'MesAnterior' ? 'Mes Anterior': filtro_atendido === 'EsteAnio' ? 'Este Año': '' } <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="card__body">
              <Chart
                options={themeReducer === 'theme-mode-dark' ? {
                  ...options1,
                  theme: { mode: 'dark'}
                } : {
                  ...options1,
                  theme: { mode: 'light'}
                }}
                series={series1}
                type='line'
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard