import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../dropdown/Dropdown';
import DropdownUser from '../DropdownUser/DropdownUser';
import NotificationService from '../../services/api/notificaciones';
// import ThemeMenu from '../thememenu/ThemeMenu';
import user_image from '../../assets/images/tuat.png';
import user_menu from '../../assets/JsonData/user_menus.json';
import './topnav.css'

class TopNav extends Component {
  state = {
    cargando: false,
    notificaciones: [],
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: ''
    }
  };

  componentDidMount () {
    this.setState({
      cargando: false
    })
    NotificationService.getNotificaciones('', (response) => {
        if (response) {
          this.setState({
            cargando: false,
            notificaciones: response.data
          })
        }
    }, (error) => {
      if (error) {
        this.setState({
          cargando: false
        })
      }
    })
  }

  componentWillMount () {
    this.setearTexto();
  }

  renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className="bx bx-error"></i>
        <div>
            <b><span>{item.title}</span></b><br/>
            <span>{item.mensaje}</span>
        </div>
    </div>
  )
  
  renderUserToggle = (user, user_image) => (
    <div className="topnav__right-user">
        <div className="topnav__right-user__image">
            <img src={ user_image } alt="" />
        </div>
        <div className="topnav__right-user__name">
            { user.name }
        </div>
    </div>
  )
  
  renderUserMenu = (item, index) => (
    <Link to='/' key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span>{item.content}</span>
        </div>
    </Link>
  )

  setearTexto = () => {
    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        let configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage
        });
      }
    }
  };

  handleSearchClick = (value) => {
    const queryString = new URLSearchParams(this.props.location.search);
    if (queryString) {
      const per_page = queryString.get('per_page');
      const number_page = queryString.get('page');
      const order_column = queryString.get('order_column');
      const order_type = queryString.get('order_type');
      let buscar_texto = value;
      let page = Object.assign({}, this.state.page);

      page.number = number_page || this.state.page.number;
      page.per_page = per_page || this.state.page.per_page;
      page.order_column = order_column || this.state.page.order_column;
      page.order_type = order_type || this.state.page.order_type;
      page.buscar_texto = buscar_texto || this.state.page.buscar_texto;

      this.setState({ page });
      
      let path = `${ this.props.location.pathname }?page=${ number_page || this.state.page.number }&per_page=${ per_page || this.state.page.per_page}&order_column=${ order_column || this.state.page.order_column }&order_type=${ order_type || this.state.page.order_type }`;
    
      if (buscar_texto && buscar_texto !== '') {
        path = `${ path }&buscar_texto=${buscar_texto}`;
      }
      this.handleChangeLocation(path);
    }
  };

  handleChangeSearch  = (value) => {
    const queryString = new URLSearchParams(this.props.location.search);
    if (queryString) {
      const per_page = queryString.get('per_page');
      const number_page = queryString.get('page');
      const order_column = queryString.get('order_column');
      const order_type = queryString.get('order_type');
      let buscar_texto = value.target.value;
      let page = Object.assign({}, this.state.page);

      page.number = number_page || this.state.page.number;
      page.per_page = per_page || this.state.page.per_page;
      page.order_column = order_column || this.state.page.order_column;
      page.order_type = order_type || this.state.page.order_type;
      page.buscar_texto = buscar_texto || this.state.page.buscar_texto;

      this.setState({ page });
      
      let path = `${ this.props.location.pathname }?page=${ number_page || this.state.page.number }&per_page=${ per_page || this.state.page.per_page}&order_column=${ order_column || this.state.page.order_column }&order_type=${ order_type || this.state.page.order_type }`;
    
      if (buscar_texto && buscar_texto !== '') {
        path = `${ path }&buscar_texto=${buscar_texto}`;
      }
      this.handleChangeLocation(path);
    }
  };

  handleChangeLocation = (path) => {
    this.props.history.push(path);
  };

  render() {
    let props = this.props;
    return (
      <div className='topnav'>
          <div className="topnav__search">
            <input 
              type="text" 
              placeholder='Buscar...'
              onChange={ this.handleChangeSearch }
              defaultValue={ this.props.location && this.props.location.search && this.state.page.buscar_texto ? this.state.page.buscar_texto: '' }
            />
            <i className='bx bx-search'></i>
          </div>
          <div className="topnav__right">
            <div className="topnav__right-item">
              <DropdownUser
                customToggle={() => this.renderUserToggle(this.props.user, user_image)}
                contentData={user_menu}
                renderItems={(item, index) => this.renderUserMenu(item, index)}
                user_menu={ user_menu }
                {...props}
              />
            </div>
            <div className="topnav__right-item">
              <Dropdown
                icon='bx bx-bell'
                badge='12'
                contentData={this.state.notificaciones}
                renderItems={(item, index) => this.renderNotificationItem(item, index)}
                user_menu={ user_menu }
                notificaciones={ this.state.notificaciones }
                renderFooter={ null }//{() => <Link to='/'>Ver todo</Link>}
              />
            </div>
            {/* <div className="topnav__right-item">
              <ThemeMenu/>
            </div> */}
          </div>
        </div>
    );
  }
}

export default TopNav;
