import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Tabs, Row, Col, Tag, Timeline, Table, Spin, Popover } from 'antd';
import MesaParteService from '../../../services/api/mesa_partes';
import ArchivoService from '../../../services/api/archivos';
import UserService from '../../../services/api/users';
import EmailFormulario from '../../../components/Email/EmailFormulario';
import {
  LinkOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';

const { TabPane } = Tabs;

class ShowMesaParte extends Component {
  state = {
    id: null,
    cargando: false,
    mesa_parte: {},
    comprobante: {},
    destinatarios: [],
    cc: [],
    usuarios: []
  }

  componentDidMount () {
    this.cargarUsuarios();
  }

  componentWillMount() {
		if (this.props.match &&
			this.props.match.params &&
			this.props.match.params.id) {
			this.cargarMesaPate(this.props.match.params.id);
		}
	}

  cargarUsuarios = () => {
    this.setState({ cargando_usuarios: true });
    UserService.getUsers('', (response) => {
      if  (response && response.data.length > 0) {
        this.setState({ usuarios: response.data });
      }
    }, (error) => {
      if (error) {
        message.error(error.message);
      }
    });
  }

  cargarMesaPate = (id) => {
    this.setState({
      cargando: true,
      id
    })

    MesaParteService.getMesaParte(id, (mesa_parte) => {
      if (mesa_parte) {

        let subject = '';
        let template = '';
        let destinatario_principal = [];
        let copias = [];

        subject = `Tu ticket de atención ${ mesa_parte.ticket_formato }`;

        const templateHeader = ``;
        const templateBody = `${ subject }`;
        const templateFooter = ``;

        template = `${ templateHeader } ${ templateBody } ${ templateFooter }`;

        destinatario_principal = [...this.state.destinatarios, mesa_parte.correo_electronico];

        this.setState({
          cargando: false,
          mesa_parte,
          destinatarios: destinatario_principal,
          cc: copias,
          subject,
          template
        })
      }
    }, (err, result) => {
      if (err) {
        message.error(err.message);
        this.setState({
          cargando: false
        })
      }
    })
  }

  handleFormSubmit = (email) => {
    email.numero_ticket = this.state.mesa_parte && this.state.mesa_parte.ticket_formato ? this.state.mesa_parte.ticket_formato: null;
    email.usuario = this.state.mesa_parte && this.state.mesa_parte.usuario_creacion_formato ? this.state.mesa_parte.usuario_creacion_formato: '-';

    this.setState({ guardando: true });
    MesaParteService.sendEmail(this.state.id, email, (response) => {
      if (response) {
        message.success('Se envió el correo con éxito');
        this.setState({ guardando: false })
      }
    }, (err) => {
      if (err) {
        this.setState({ guardando: false });
      }
    })
  }

  render() {
    const { mesa_parte } = this.state;
    const columns = [
      {
        title: 'Fecha',
        dataIndex: 'fecha_descripcion',
        key: 'fecha_descripcion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Estado',
        dataIndex: 'tipo_operacion',
        key: 'tipo_operacion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text) => {
          return (
            <div>
              <Tag color={text === 'RECIBIDO' ? 'blue':text === 'EN ATENCIÓN' ? 'green':text === 'ARCHIVADO' ? 'red': '' }>{ text }</Tag>
            </div>
          );
        },
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable',
        key: 'responsable',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Email Descrpción',
        dataIndex: 'comentario',
        key: 'comentario',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Ajuntos',
        dataIndex: 'ticket_formato',
        key: 'ticket_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (<div>
              <Popover placement="bottomRight" content={
                record.files &&
                record.files.length > 0&&
                record.files.map(file => {
                  return (
                    <div>
                    <p><CloudDownloadOutlined/> <a href={ArchivoService.getArchivoUrl(file.id)} target='_blank'>{ file.nombre_original }</a></p>
                  </div>
                  );
                })
              } title="Descargar Archivos">
                <LinkOutlined />
              </Popover>
          </div>);
        },
      }
    ];
    return (
      <div>
        <h2 className="page-header">{ mesa_parte.responsable ? mesa_parte.responsable: 'Detalle Mesa Parte' }</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Información General" key="1">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="line-height-28">
                          <b>TICKET</b> { mesa_parte.ticket_formato }
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div><b>Estado Reclamo: </b>
                          <Tag color={ mesa_parte.estado === 'RECIBIDO' ? 'blue': mesa_parte.estado === 'EN ATENCIÓN' ? 'green': mesa_parte.estado === 'ARCHIVADO' ? 'red': '' }>{ mesa_parte.estado }</Tag>
                        </div>
                        <div><b>Tipo Documento: </b> { mesa_parte.tipo_documento_formato }</div>
                        <div><b>Nombres: </b> { null }</div>
                        <div><b>Menor de Edad: </b> { null }</div>
                        <div><b>Responsable: </b> { mesa_parte.responsable }</div>
                        <div><b>Dirección: </b> { mesa_parte.descripcion }</div>
                        <div><b>Provincia: </b>{ null }</div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div><b>Correo: </b> { mesa_parte.correo_electronico }</div>
                        <div><b>N° Documento: </b> { mesa_parte.numero_documento }</div>
                        <div><b>Télefono: </b> { mesa_parte.celular }</div>
                        <div><b>Nombre del Apoderado: </b> { null }</div>
                        <div><b>Fecha Incidencia: </b> { null }</div>
                        <div><b>Departamento: </b>{ null }</div>
                        <div><b>Distrito: </b>{ mesa_parte.direccion }</div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <Timeline mode="left">
                          {
                            mesa_parte.historiales &&
                            mesa_parte.historiales.length > 0 &&
                            mesa_parte.historiales.map((item,index) => {
                              return (
                                <Timeline.Item key={ index } label={ item.fecha_formato } color={ item.tipo_operacion === 'RECIBIDO' ? 'blue': item.tipo_operacion === 'ATENDIDO' ? 'green': item.tipo_operacion === 'RECHAZADO' ? 'red': '' }>
                                  <Tag color={ item.tipo_operacion === 'RECIBIDO' ? 'blue': item.tipo_operacion === 'ATENDIDO' ? 'green': item.tipo_operacion === 'RECHAZADO' ? 'red': '' }>{ item.tipo_operacion }</Tag>
                                  <div>{ item.comentario }</div>
                                </Timeline.Item>
                              );
                            })
                          }
                        </Timeline>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br/>
                          <div><b>DETALLE DE TRAMÍTE: </b></div>
                          <div><b>Tipo: </b> { mesa_parte.tipo_tramite_formato }</div>
                          <div><b>Detalle: </b> { mesa_parte.contenido }</div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Estados" key="2">
                    <Row>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Table
                          rowKey={ record => record.id }
                          columns={ columns }
                          dataSource={ mesa_parte.historiales }
                          pagination={ false }
                          loading={ this.state.cargando }
                          size="small"
                        />
                        <br/>
                        <div>
                        {
                          !this.state.cargando ?
                          <EmailFormulario
                            from={ this.props.user.email }
                            destinatarios={ this.state.destinatarios }
                            cc={ this.state.cc }
                            subject={ this.state.subject }
                            template={ this.state.template }
                            serie_formato={ this.state.comprobante.serie_formato}
                            onFormSubmit={ this.handleFormSubmit }
                            onFormClose={ this.handleClickCloseEmail }
                            guardando={ this.state.guardando }
                            permisos={ this.props.permisos }
                            usuarios={ this.state.usuarios }
                            historiales={ mesa_parte.historiales }
                          />:
                          <Spin tip="Cargando...">
                            <EmailFormulario />
                          </Spin>
                        }
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos: []
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowMesaParte);