import React, { Component } from 'react';

class IndexControlCita extends Component {
  render() {
    return (
      <div>
        <h2 className="page-header">Control de Citas</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                Muy Pronto
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndexControlCita;