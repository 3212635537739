import apiConfig from './config';
import data from './data-json/permisos.json';

const getRoles = (queryString, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/roles${ queryString }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
	.then(apiConfig.methods.checkStatus)
	.then(apiConfig.methods.parseJSON)
	.then(success)
	.catch(apiConfig.methods.error)
	.then(error);
};

const getRol = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/roles/${ id }`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const createRol = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/roles`, {
		method: 'post',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const updateRol = (data, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/roles/${ data.id }`, {
		method: 'put',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const deleteRol = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/roles/${ id }`, {
		method: 'delete',
		headers: {
			Authorization: `Bearer ${ token }`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const getPermisos = (id, success, error) => {
	const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

	return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/rol/${ id }/permisos`, {
		headers: {
			Authorization: `Bearer ${ token }`,
			Accept: 'application/json'
		}
	})
		.then(apiConfig.methods.checkStatus)
		.then(apiConfig.methods.parseJSON)
		.then(success)
		.catch(apiConfig.methods.error)
		.then(error);
};

const getPermisosJson = (success, error) => {
	let promise = Promise.resolve(data);
	return promise.then(success);
}

const exportedObject = {
	getRoles,
	getRol,
	createRol,
	updateRol,
	deleteRol,
	getPermisos,
	getPermisosJson
}

export default exportedObject;