import React, { Component } from 'react';
import { Table, message, Space, Pagination, Button, Popconfirm } from 'antd';
import UserFormularioModal from '../../../../components/Users/UserFormularioModal';
import UserService from '../../../../services/api/users';

import {
  QuestionCircleOutlined
} from '@ant-design/icons';

class IndexUsers extends Component {
  state = {
    cargando: false,
    users: [],
    open_modal_user: false,
    guardando: false,
    cargando_roles: false,
    roles: [],
    page: {
			number: 1,
			per_page: 15,
			order_column: 'created_at',
			order_type: 'desc',
			from: 0,
			to: 0,
			last_page: 0,
			total: 0,
			buscar_texto: ''
		}
  };

  setFormModal = (ref) => {
    this.formModalRef = ref;
  };


  componentWillUnmount() {
    this.unlisten();
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);

    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        let configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage
        });
      }
    }    
  }

  componentDidMount () {
    this.cargarUsers();
  }

  cargarUsers = (path = undefined) => {
    this.setState({
      cargando: true
    })

    if (!path || path === undefined) {
      path =  `?page=${ this.state.page.number }&per_page=${ this.state.page.per_page }&order_column=${ this.state.page.order_column }&order_type=${ this.state.page.order_type }`; 
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${ path }&buscar_texto=${ this.state.page.buscar_texto }`;
      }
    }

    UserService.getUsers(path, (page) => {
      if (page && page.data && page.data.length > 0) {
        let configPage = Object.assign({}, this.state.page);
				configPage.from = page.from;
				configPage.to = page.to;
				configPage.last_page = page.last_page;
				configPage.total = page.total;
				configPage.number = page.current_page;
        configPage.per_page = parseInt(page.per_page);
        configPage.buscar_texto = page.buscar_texto ? page.buscar_texto: '';
        
        this.setState({
          cargando: false,
          users: page.data,
          page: configPage
        });
      } else {
        this.setState({
					users: [],
					cargando: false
				});
      }
    }, (error, result) => {
      if (error) {
        message.error(error.message);
        this.setState({
          cargando: false
        })
      }
    })
  }

  handleChangeLocation = (location, action) => {
		const queryString = new URLSearchParams(location.search);
		const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      let page = Object.assign({}, this.state.page);
    
      page.buscar_texto = buscar_texto;

      this.setState({
        page
      });

      setTimeout(() => {
        this.cargarUsers();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    let page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${ page.number }&per_page=${ page.per_page }&order_column=${ page.order_column }&order_type=${ page.order_type }`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== "") {
      path = `${ path }&buscar_texto=${ page.buscar_texto }`;
    }
    this.props.history.push(path);
    this.cargarUsers(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  handleEditarUser = (user) => {
    this.setState({
      open_modal_user: true,
      user
    });
  };

  handleCreateFormSubmit = (data) => {
    this.setState({guardando: true});
    if (data.id) {
      UserService.updateUser(data, (response) => {
        if (response) {
          this.setState({
            guardando: false,
            open_modal_user: false,
            response
          });
          this.cargarUsers();
          message.success("Datos actualizados exitosamente");
        }
      }, (error) => {
        if (error) {
          if (this.formModalRef) {
            this.formModalRef.onFormUploadValidators(error);
          }
          message.error(error.message);
          this.setState({
            guardando: false,
            open_modal_user: true
          });
        }
      })
    } else {
      UserService.createUser(data, (response) => {
        if (response) {
          this.setState({
            guardando: false,
            open_modal_user: false,
            response
          });
          this.cargarUsers();
          message.success("Datos guardados exitosamente");
        }
      }, (error) => {
        if (error) {
          if (this.formModalRef) {
            this.formModalRef.onFormUploadValidators(error);
          }
          message.error(error.message);
          this.setState({
            guardando: false,
            open_modal_user: true
          });
        }
      })
    }
  };

  handleClickCloseMarcaModal = (e) => {
    this.setState({
      open_modal_user: false,
    });
  };

  handleClickOpenUserFormularioModal = () => {
    this.setState({
      user: {},
      open_modal_user: true,
      buscar_texto: false
    });
  };


  handleDelete = (user_id) => {
    UserService.deleteUser(user_id, (response) => {
      if (response) {
        this.setState({
          response
        });
        this.cargarUsers();
        message.success('El usuario seleccionada ha sido eliminada');
      }
    }, (error) => {
      if (error) {
        message.error(error.message);
      }
    })
  };

  render() {
    const columns = [
      {
        title: 'Nombres',
        dataIndex: 'name',
        key: 'name',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Correo Electrónico',
        dataIndex: 'email',
        key: 'email',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => (
          <Space size="middle">
            <a onClick={ () => this.handleEditarUser(record) }>Editar</a>
            <Popconfirm
              title={`¿Estas seguro que deseas eliminar ${record.nombre ? record.nombre: ''}？`}
              cancelText="Cancelar"
              okText="Eliminar"
              okType="danger"
              onConfirm={ () => { this.handleDelete(record.id); } }
              icon={ <QuestionCircleOutlined style={{ color: 'red' }}/> }>
              <div className="link">Eliminar</div>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 className="page-header">Lista de Usuarios</h2>
          <div>
            <Button type="primary" size="large" onClick={ this.handleClickOpenUserFormularioModal }>Nuevo Usuario</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <Table
                  rowKey={record => record.id}
                  columns={columns}
                  dataSource={ this.state.users }
                  pagination={ false }
                  loading={ this.state.cargando }
                  size="small"
                />
                <div style={{ paddingTop: 12, textAlign: 'right' }}>
                  <Pagination
                    size="small"
                    showTotal={ this.showTotal }
                    total={ this.state.page.total }
                    current = { this.state.page.number }
                    pageSize = { this.state.page.per_page }
                    onChange={ this.handleChangePerPage }
                    onShowSizeChange={this.handleChangePerPage}
                    pageSizeOptions={[10,20,30,40]}
                    showQuickJumper
                    showSizeChanger
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        { this.state.open_modal_user ?
          <UserFormularioModal
            visible={ this.state.open_modal_user }
            guardando={ this.state.guardando }
            user={ this.state.user }
            onFormSubmit={ this.handleCreateFormSubmit }
            onCancel={this.handleClickCloseMarcaModal}
            ref={ this.setFormModal }
          /> : ''
        }
      </div>
    );
  }
}

export default IndexUsers;