import React, { Component } from 'react';

//import { createStore } from 'redux';
import { Provider } from 'react-redux';
//import rootReducer from './redux/reducers';

import {
  BrowserRouter as Router,
  Switch,
  Route } from 'react-router-dom';

import Private from './Private/Private';
import Login from './Public/scenes/Login/Login';
import IndexMpv from './Public/scenes/Mpv/index';

// HOC
import Authorization from './hoc/authorization';
// Store
import store from './store';
import './App.css';
import './main.less';

class App extends Component {
  render() {
    const AuthFull = Authorization('full');
    return (
      <Provider store={store}>
        <React.StrictMode>
          <Router>
            <Switch>
              <Route exact path='/auth/login' name='Página Inicio' component={ Login } />
              <Route exact path='/mpv' name='Formulario Mesa de Partes' component={ IndexMpv } />
              <Route path='/' name='Home' component={ AuthFull(Private, 'full') } />
            </Switch>
          </Router>
        </React.StrictMode>
      </Provider>
    );
  }
}

export default App;