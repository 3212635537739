import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import "./Private.css";

import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";

import Dashboard from "../pages/Dashboard";
import Customers from "../pages/Customers";
import IndexMesaParte from "../Private/scenes/MesaPartes/index";
import ShowMesaParte from "../Private/scenes/MesaPartes/show";
import IndexControlCitas from "../Private/scenes/ControlCitas/index";
import IndexGestionColegiaturas from "../Private/scenes/GestionColegiaturas/index"
import IndexEstadoCuentas from "../Private/scenes/EstadoCuentas/index";
import IndexUser from "./scenes/Configuraciones/Users/index";
import IndexRol from "../Private/scenes/Configuraciones/Roles/index";

import { useDispatch } from "react-redux";
import ThemeAction from "../redux/actions/ThemeAction";

const Layout = (props) => {
  // const themeReducer = useSelector(state => state.ThemeReducer)
  const dispatch = useDispatch();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(ThemeAction.setColor(colorClass));
  }, [dispatch]);
  
  return (
    <div className={`layout`}>
      <Sidebar {...props} />
      <div className="layout__content">
        <TopNav {...props} />
        <div className="layout__content-main">
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/customers" component={Customers} />
            <Route
              path="/mesa-partes/:id/show"
              name="Detalle Compra"
              render={(props) => <ShowMesaParte {...props} />}
            />

            <Route
              path="/mesa-partes/:id?"
              name="Mesa Partes"
              render={(props) => <IndexMesaParte {...props} />}
            />

            <Route
              path="/control-citas/:id?"
              name="Control de Citas"
              render={(props) => <IndexControlCitas {...props} />}
            />

            <Route
              path="/gestion-colegiaturas/:id?"
              name="Gestión Colegiaturas"
              render={(props) => <IndexGestionColegiaturas {...props} />}
            />

            <Route
              path="/estado-cuentas/:id?"
              name="Estado de Cuenta"
              render={(props) => <IndexEstadoCuentas {...props} />}
            />

            <Route
              path="/configuraciones/users/:id?"
              name="Users"
              render={(props) => <IndexUser {...props} />}
            />

            <Route
              path="/configuraciones/roles/:id?"
              name="Roles"
              render={(props) => <IndexRol {...props} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
